.CircleLoader {
  text-align: center;
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  animation: slideInFromBottom 1s ease-out 2s forwards;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@media (max-width: 640px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .text-5xl {
    font-size: 2rem;
  }

  .text-3xl {
    font-size: 1.5rem;
  }

  .py-8 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
