@font-face {
    font-family: 'sf_bold';
    src: url(../../assets/fonts/sanfrancisco/SF-Pro-Display-Bold.otf);
    font-style: normal;
    font-weight: 600;
  }
  @font-face {
    font-family: 'sf_regular';
    src: url(../../assets/fonts/sanfrancisco/SF-Pro-Display-Regular.otf);
  }
  @font-face {
      font-family: 'sf_light';
      src: url(../../assets/fonts/sanfrancisco/SF-Pro-Display-Light.otf);
  }
  @font-face {
      font-family: 'sf_medium';
      src: url(../../assets/fonts/sanfrancisco/SF-Pro-Display-Medium.otf);
  }


.info {
    width: 300px;
    
}

.rights {
    width:250px;
}

.social {
    display:flex;
   @media (max-width: 768px) {
       margin-left: 20px;
       align-items:center;
       justify:center;
   }
}



