@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
  li {
    @apply p-4
  }


}